import { useQuery } from "@tanstack/react-query"
import {
  Composition,
  MedicationKnowledge,
  Organization,
  codeableConceptAsString,
  getResources,
  getResourcesByTypeAsIndex,
} from "fhir"
import { useEffect, useMemo } from "react"

import { useClient } from "api"
import { MEDICATION_CATALOG } from "data"
import { getCommonCode, getMedCodes } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationKnowledge = ({
  filter = "",
  catalogId,
  medCodes = "",
  enabled = true,
  category,
  onSettled,
  hasCIDInOrg,
  fromPharmacy,
}: Props) => {
  const queryKey = medsQueryKeys.medicationKnowledge(
    catalogId,
    filter,
    medCodes,
    fromPharmacy ? MEDICATION_CATALOG.RX : category,
  )
  const getMKFunction = useMedicationKnowledgeQueryFunction()

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) =>
      getMKFunction({
        filter,
        catalogId,
        medCodes,
        signal,
        category: fromPharmacy ? MEDICATION_CATALOG.RX : category,
        hasCIDInOrg,
      }),
    enabled,
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, catalogId, filter, medCodes } },
  })

  const { medicationKnowledge, mkCodes, catalogs, mksBySku } = useMemo(() => {
    const mksBySku = (data?.medicationKnowledge ?? [])?.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )

    const catalogPerformer = data?.catalogs.reduce(
      (acc, cat) => {
        const performer = data.organizations?.[cat.author?.[0]?.id as string]
        if (performer) return { ...acc, [cat.id as string]: performer }
        return { ...acc }
      },
      {} as Record<string, Organization>,
    )

    return {
      medicationKnowledge: (data?.medicationKnowledge ?? []).sort((a, b) =>
        codeableConceptAsString(a.code).localeCompare(codeableConceptAsString(b.code)),
      ),
      mkCodes: getMedCodes({ meds: data?.medicationKnowledge }),
      catalogs: catalogPerformer ?? {},
      mksBySku,
    }
  }, [data])

  useEffect(() => {
    onSettled?.(medicationKnowledge, error)
  }, [medicationKnowledge, error])

  return {
    medicationKnowledge,
    medicationKnowledgeCodes: mkCodes,
    isLoading: isLoading && isFetching,
    catalogs,
    mksBySku,
  }
}

const useMedicationKnowledgeQueryFunction = () => {
  const { search } = useClient()

  return async ({
    filter,
    catalogId,
    medCodes,
    signal,
    category,
    hasCIDInOrg,
  }: {
    filter?: string
    catalogId?: string
    medCodes?: string
    signal?: AbortSignal
    category?: MEDICATION_CATALOG
    hasCIDInOrg?: string
  }) => {
    const filters = new URLSearchParams({
      ...(catalogId ? { catalogHeader: catalogId } : {}),
      ...(filter ? { termsearch: filter } : {}),
      ...(medCodes ? { code: medCodes } : {}),
      _count: "100",
      _sort: "code",
      status: "active",
      _include: "catalogHeader:Composition,Composition:author:Organization",
      ...(category ? { "catalogHeader:Composition.author:Organization.type": category } : {}),
      ...(hasCIDInOrg ? { "has-cid-in-org": hasCIDInOrg } : {}),
    })

    const mkBundle = await search({ endpoint: "MedicationKnowledge", filters, signal })

    const medicationKnowledge = getResources<MedicationKnowledge>(mkBundle, "MedicationKnowledge")
    const catalogs = getResources<Composition>(mkBundle, "Composition")
    const organizations = getResourcesByTypeAsIndex<Organization>(mkBundle, "Organization")

    return {
      catalogs,
      medicationKnowledge,
      organizations,
    }
  }
}

type Props = {
  filter?: string
  catalogId?: string
  medCodes?: string
  fromPharmacy?: boolean
  enabled?: boolean
  onSettled?(data: MedicationKnowledge[] | undefined, error: Error | null): void
  category?: MEDICATION_CATALOG
  hasCIDInOrg?: string
}

export { useMedicationKnowledge, useMedicationKnowledgeQueryFunction }
