import { faCalendarClock } from "@fortawesome/pro-regular-svg-icons"
import { Sidebar } from "primereact/sidebar"
import { useState } from "react"

import { Button, useWindowBounds } from "commons"

import { AppointmentHistory } from "./AppointmentHistory"

const AppointmentHistoryContainer = ({ planId, selectedPlanId, setSelectedPlanId }: Props) => {
  const { isSmallScreen } = useWindowBounds()
  const [showSideBar, setShowSideBar] = useState(false)

  return isSmallScreen ? (
    <>
      <div className="-ml-[7.5rem] w-24 pt-2.5">
        <Button label="History" icon={faCalendarClock} buttonStyle="default" onClick={() => setShowSideBar(true)} />
      </div>
      <Sidebar visible={showSideBar} onHide={() => setShowSideBar(false)} className="sidebar-menu" position="right">
        <AppointmentHistory
          carePlanId={planId}
          selectedItemId={selectedPlanId}
          onSelectHisoryItem={(planId) => {
            setSelectedPlanId(planId)
            setShowSideBar(false)
          }}
        />
      </Sidebar>
    </>
  ) : (
    <AppointmentHistory carePlanId={planId} selectedItemId={selectedPlanId} onSelectHisoryItem={setSelectedPlanId} />
  )
}

type Props = {
  planId: string
  selectedPlanId: string
  setSelectedPlanId(planId: string): void
}

export { AppointmentHistoryContainer }
