import { useMutation } from "@tanstack/react-query"
import { Bundle, getResources, MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useStopMedicationRequest = (args?: Args) => {
  const { transaction } = useClient()

  const stopMedicationReq = async (medicationRequestIds: string[]) => {
    const bundleRequest: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: medicationRequestIds.map((id) => {
        return {
          request: {
            method: "POST",
            url: `MedicationRequest/${id}/$stop`,
          },
        }
      }),
    }

    return transaction(bundleRequest)
  }
  const { mutate: stopMedicationRequest, isPending } = useMutation({
    mutationFn: stopMedicationReq,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async (meds) => {
      const medications = getResources<MedicationRequest>(meds as Bundle)
      args?.refreshMedications?.(medications)
      displayNotificationSuccess("Prescription stopped successfully!")
    },
    onSettled: args?.onSettled,
  })

  return { stopMedicationRequest, isLoading: isPending }
}

type Args = { onSettled?: () => void; refreshMedications?: (meds: MedicationRequest[]) => void }

export { useStopMedicationRequest }
