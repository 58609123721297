import {
  ChargeItemDefinition,
  CodeableConcept,
  Composition,
  Money,
  Organization,
  PlanDefinition,
  asReference,
} from "fhir"

import { convertIdentifiersToCodings, getBasePrice, getCommonCode } from "utils"
import { COMBO_PROMO_CODE } from "data"

import { CarePlanHistory, ComboDefinition, PlanData } from "./types"

const planStatusOptions = (isOrder = false) => [
  { display: isOrder ? "Pending" : "Ready", code: "active" },
  /*   { display: isOrder ? "Draft" : "Pending", code: "draft" }, */
  { display: "Completed", code: "completed" },
  { display: "Cancelled", code: "revoked" },
]

const getAssessmentStatusText = (status: string) => ({ active: "ready", draft: "pending" })[status] ?? status

const isPDType = (pd: PlanDefinition, type: "panel" | "combo" | "clinical-protocol" | "follow-up" | "mc") =>
  pd.type?.coding?.some(({ code }) => code === type)

const getComboDefinition = ({
  comboPanel,
  cids,
  panelDefinitions,
  catalogs,
  organizations,
}: {
  comboPanel: PlanDefinition
  panelDefinitions: Record<string, PlanDefinition>
  cids: Record<string, ChargeItemDefinition>
  catalogs: Record<string, Composition>
  organizations: Record<string, Organization>
}): ComboDefinition => {
  const canonicalPanels =
    comboPanel.action?.reduce<Array<string>>(
      (panels, pdAction) => [...panels, pdAction.definition?.canonical as string],
      [],
    ) ?? []

  const promoCoding = comboPanel.topic?.find((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)?.coding?.[0]

  const comboPanels =
    canonicalPanels?.reduce<Array<PlanDefinition>>(
      (cpanels, canonical) =>
        panelDefinitions[canonical as string] ? [...cpanels, panelDefinitions[canonical as string]] : cpanels,
      [],
    ) ?? []

  const comboCode = convertIdentifiersToCodings(comboPanel ? [comboPanel] : [])
  const comboCid = cids[getCommonCode({ codes: comboCode })]

  return {
    canonical: `${comboPanel.url}|${comboPanel.version}`,
    canonicalPanels,
    definition: comboPanel,
    promoCoding,
    panels: comboPanels,
    price: getBasePrice(comboCid?.propertyGroup?.[0]?.priceComponent)?.amount as Money,
    performer: getPDPerformer(comboPanel, catalogs, organizations),
  }
}

const getPDPerformer = (
  pd: PlanDefinition,
  catalogs: Record<string, Composition>,
  organizations: Record<string, Organization>,
) => {
  const performer = organizations[catalogs[pd.catalogHeader?.[0]?.id as string]?.author?.[0]?.id as string]
  return performer ? asReference(performer) : {}
}

const getActionCode = (cc: CodeableConcept | undefined) =>
  cc?.coding?.find(({ system }) => system === "http://chartedhealth.com/fhir/plans/action")?.code

const getCPDate = (data: CarePlanHistory | PlanData) =>
  new Date(data.appointment?.start ?? (data.carePlan.created as string))

export { getActionCode, getComboDefinition, isPDType, getAssessmentStatusText, planStatusOptions, getCPDate }
