import { useQuery } from "@tanstack/react-query"
import {
  ActivityDefinition,
  Bundle,
  ChargeItemDefinition,
  codeableConceptAsString,
  Coverage,
  getResources,
  isChargeItemDefinition,
  isCoverage,
  Medication,
  MedicationKnowledge,
  MedicationRequest,
  RequestGroup,
  RequestGroupActionArrayActionArray,
} from "fhir"
import pluralize from "pluralize"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { MEDICATIONS_REGULATIONS_CODE } from "commons/meds"
import { MEDICATION_PRODUCT_TYPE } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getBasePrice, getCidIdentifier, getCommonCode, getMedCodes, isMrMedication, isMrProcedure } from "utils"

import { cpoeOrdersKeys } from "../query-keys"
import { ACTION_GROUP_CODES, ActionGroupCode, CpoeRequest, RG_BILLING_ACTION_CODES } from "../types"
import { checkIsActionType } from "../utils"
import { GroupedChargeItemDefinitionsCodes } from "commons/types"

const useCpoeOrders = (organizationId: string, patientId: string, enabled?: boolean) => {
  const { operationRequest, transaction } = useClient()
  const queryKey = cpoeOrdersKeys.withPatientId(patientId)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetching, isError, isRefetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const rg = await operationRequest<RequestGroup>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "dwim",
      })

      if (!rg || !rg.action?.[0].action) {
        return {
          medicationRequestActions: new Array<RequestGroupActionArrayActionArray>(),
          medicationRequests: { nutraMrs: [], rxMrs: [] },
          medicationKnowledges: [],
          chargeItemDefinitions: {} as {
            billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
            billToPatientCIDs: Record<string, ChargeItemDefinition>
          },
          activityDefinition: undefined,
          requestGroup: undefined,
          nutraceuticalActions: [],
        } as {
          medicationRequestActions: RequestGroupActionArrayActionArray[]
          nutraceuticalActions: RequestGroupActionArrayActionArray[]
          medicationRequests: { nutraMrs: MedicationRequest[]; rxMrs: MedicationRequest[] }
          medicationKnowledges: MedicationKnowledge[]
          chargeItemDefinitions: {
            billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
            billToPatientCIDs: Record<string, ChargeItemDefinition>
          }
          activityDefinition: ActivityDefinition | undefined
          requestGroup: RequestGroup | undefined
        }
      }

      const medicationRequestActions = rg.action[0].action.filter(
        ({ resource, code }) =>
          resource?.resourceType === "MedicationRequest" &&
          checkIsActionType({ codeableConcept: code, type: ACTION_GROUP_CODES.PHARMA }),
      )

      // Fallback Rx to nutra temporally
      // Optim code is  checkIsActionType({ codeableConcept: code, type: "nutraceutical" }) && resource?.resourceType === "MedicationRequest"
      // When all RGs have the new structure use optim code ^
      const nutraceuticalActions = rg.action[0].action.filter(
        ({ resource, code }) =>
          checkIsActionType({ codeableConcept: code, type: ACTION_GROUP_CODES.NUTRA }) ||
          (!medicationRequestActions.length && resource?.resourceType === "MedicationRequest"),
      )

      const medActionIds = [...medicationRequestActions, ...nutraceuticalActions].map((req) => req.resource?.id)
      const activityDefinitionReference = rg.action[1].resource

      const filters = new URLSearchParams({
        _query: "cpoe-medication-data",
        _patient: patientId,
        _activityDefinition: activityDefinitionReference?.id as string,
        _medicationRequests: medActionIds.join(","),
      })

      const reqBundle: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: [
          {
            request: {
              method: "GET",
              url: `/MedicationRequest?${filters}`,
            },
          },
        ],
      }

      const respBundle = await transaction(reqBundle)

      const medBundle = (respBundle as Bundle).entry?.[0]?.resource as Bundle

      const medicationRequests = getResources<MedicationRequest>(medBundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(medBundle, "MedicationKnowledge")
      const activityDefinition = getResources<ActivityDefinition>(medBundle, "ActivityDefinition")?.[0]

      let codes: GroupedChargeItemDefinitionsCodes = { billToPatientCIDs: [] }

      const { nutraMrs, rxMrs } = medicationRequests.reduce(
        (acc, mr) => {
          const nutra = nutraceuticalActions.find((action) => mr.id === action.resource?.id)

          if (nutra) {
            return { ...acc, nutraMrs: [...acc.nutraMrs, mr] }
          } else {
            return { ...acc, rxMrs: [...acc.rxMrs, mr] }
          }
        },
        {
          nutraMrs: [] as MedicationRequest[],
          rxMrs: [] as MedicationRequest[],
        },
      )
      const nutraMedCodes = getMedCodes({ meds: nutraMrs, withQty: true }) ?? []
      const rxMedCodes = getMedCodes({ meds: rxMrs, withQty: true }) ?? []

      if (nutraMedCodes?.length || rxMedCodes?.length)
        codes = {
          billToPatientCIDs: [...(codes.billToPatientCIDs ?? []), ...nutraMedCodes, ...rxMedCodes],
        }

      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, codes)

      return {
        medicationRequestActions,
        medicationRequests: { nutraMrs, rxMrs },
        medicationKnowledges,
        chargeItemDefinitions,
        activityDefinition,
        requestGroup: rg,
        nutraceuticalActions,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, patientId } },
    enabled,
  })

  // Link RG actions with original MR or SR
  const {
    cpoeRequests,
    activeRequests,
    activityDefinition,
    shippingMethods,
    isOnlyDfo,
    discounts,
    coveragesByType,
    isOnlyMedication,
  } = useMemo(() => {
    let cpoeRequests: CpoeRequest[] = []
    data?.chargeItemDefinitions

    // Add medication requests
    if (data?.medicationRequests?.rxMrs) {
      cpoeRequests = data?.medicationRequests?.rxMrs.reduce((prev, mr) => {
        const action = data.medicationRequestActions.find(
          (action) => mr.id === action.resource?.id,
        ) as RequestGroupActionArrayActionArray
        const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })
        const mk = data.medicationKnowledges.find((mk) => getCommonCode({ codes: mk.code?.coding }) === mrCode)

        if (mr) {
          return [
            ...prev,
            {
              ...getMRData(action, mr, mrCode, mk, data.chargeItemDefinitions.billToPatientCIDs),
              type: ACTION_GROUP_CODES.PHARMA,
            },
          ]
        }

        return prev
      }, cpoeRequests)
    }

    // Add nutraceutical requests
    if (data?.medicationRequests?.nutraMrs) {
      cpoeRequests = data.medicationRequests?.nutraMrs.reduce((prev, mr) => {
        const action = data.nutraceuticalActions.find(
          (action) => mr.id === action.resource?.id,
        ) as RequestGroupActionArrayActionArray
        const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })
        const mk = data.medicationKnowledges.find((mk) => getCommonCode({ codes: mk.code?.coding }) === mrCode)

        if (mr) {
          return [
            ...prev,
            {
              ...getMRData(action, mr, mrCode, mk, data.chargeItemDefinitions.billToPatientCIDs),
              type: ACTION_GROUP_CODES.NUTRA,
            },
          ]
        }

        return prev
      }, cpoeRequests)
    }

    const activeRequests = cpoeRequests?.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate") ?? []

    const shippingMethods = data?.requestGroup?.contained?.filter(
      (res) =>
        isChargeItemDefinition(res) &&
        res.code?.coding?.some((coding) =>
          ([SYSTEM_VALUES.SHIPPING_METHOD, SYSTEM_VALUES.SERVICE_FEE] as string[]).includes(coding.system as string),
        ),
    ) as ChargeItemDefinition[] | undefined

    const discounts = data?.requestGroup?.contained?.filter(
      (res) =>
        isChargeItemDefinition(res) && res.code?.coding?.some((coding) => coding.system === SYSTEM_VALUES.DISCOUNT),
    ) as ChargeItemDefinition[] | undefined

    const coverages = data?.requestGroup?.contained?.filter(
      (res) => isCoverage(res) && res.type?.coding?.some((coding) => coding.system === SYSTEM_VALUES.COVERAGE_TYPE),
    ) as Coverage[] | undefined
    const coveragesByType = coverages?.reduce(
      (acc, coverage) => {
        const billingAction = data?.requestGroup?.action
          ?.find(({ code }) => code?.[0]?.coding?.some(({ code }) => code === "configure-payment"))
          ?.action?.find((action) => action.resource?.localRef === coverage.id)
        if (billingAction?.code?.[0]?.coding?.some(({ code }) => code === RG_BILLING_ACTION_CODES.PHARMA)) {
          return { ...acc, [ACTION_GROUP_CODES.PHARMA]: coverage }
        }

        if (billingAction?.code?.[0]?.coding?.some(({ code }) => code === RG_BILLING_ACTION_CODES.NUTRA)) {
          return { ...acc, [ACTION_GROUP_CODES.NUTRA]: coverage }
        }

        return { ...acc }
      },
      {} as Record<ActionGroupCode, Coverage>,
    )

    const isOnlyDfo =
      cpoeRequests
        ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
        .every((med) => med.medicationData?.isDfo) ?? false

    const isOnlyMedication =
      cpoeRequests
        ?.filter((req) => req.type === ACTION_GROUP_CODES.PHARMA || req.type === ACTION_GROUP_CODES.NUTRA)
        .every((med) => med.medicationData?.isMedication) ?? false

    return {
      cpoeRequests,
      activityDefinition: data?.activityDefinition,
      activeRequests,
      shippingMethods,
      isOnlyDfo,
      isOnlyMedication,
      discounts,
      coveragesByType,
    }
  }, [data])

  return {
    cpoeRequests,
    activeRequests,
    requestGroup: data?.requestGroup,
    chargeItemDefintions: data?.chargeItemDefinitions,
    activityDefinition,
    requestShippingMethods: shippingMethods,
    requestDiscounts: discounts,
    requestCoverage: coveragesByType,
    isLoading,
    isFetching,
    isError,
    isOnlyDfo,
    isOnlyMedication,
    isRefetching,
  }
}

const getMRData = (
  action: RequestGroupActionArrayActionArray,
  mr: MedicationRequest,
  mrCode: string,
  mk?: MedicationKnowledge,
  billToPracticeOrInsuranceCIDs?: Record<string, ChargeItemDefinition>,
) => {
  const isRX = mr.category?.[0]?.coding?.some(({ code }) => code === MEDICATION_PRODUCT_TYPE.RX)
  const doseForm = isRX ? mk?.doseForm?.coding?.[0]?.display : undefined
  const packaging = isRX ? mk?.packaging?.type?.coding?.[0]?.display : undefined
  const maxDaysSupplyLimit = mk?.regulatory?.find(
    (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DAYS_SUPPLY,
  )?.regulatoryCharacteristic?.[0]?.value?.quantity?.value
  const qty = mr.dispenseRequest?.quantity
  const strength = mk?.ingredient?.[0]?.strength?.numerator

  return {
    resource: {
      ...action,
      timing: { ...action.timing, dateTime: new Date().toISOString() },
    },
    display: `${
      (mr.contained?.[0] as Medication)?.code?.text ?? codeableConceptAsString(mr.medication?.CodeableConcept)
    } (${qty?.value} ${isRX ? qty?.unit : pluralize(qty?.unit ?? "", qty?.value ?? 1)} ${strength?.unit ? `${!isRX ? strength.value ?? "" : ""}${strength.unit}` : ""})`,
    medicationData: {
      manufacturer: mk?.manufacturer?.display ?? "Unspecified",
      medicationRequest: mr,
      dispenseRequest: mr.dispenseRequest,
      ...(isRX ? { maxDaysSupplyLimit } : {}),
      isDfo: mr.dispenseRequest?.initialFill?.isDfo,
      isProcedure: isMrProcedure(mr),
      isMedication: isMrMedication(mr),
      doseForm,
      packaging,
    },
    quantity: mr.dispenseRequest?.quantity?.value ?? 1,
    unitPrice: getBasePrice(
      billToPracticeOrInsuranceCIDs?.[getCidIdentifier(mrCode, mr?.dispenseRequest?.quantity?.value)]
        ?.propertyGroup?.[0].priceComponent,
    )?.amount ?? {
      currency: "USD",
      value: 0,
    },
  }
}

export { useCpoeOrders }
