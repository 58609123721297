const INVOICE_ORDER_TYPE_MAP = {
  nutraceutical: {
    module: "nutraceuticals",
    subview: { completed: "orders", default: "orders" },
    orderParam: "order",
  },
  pharmaceutical: {
    module: "pharmaceuticals",
    subview: { completed: "orders", default: "orders" },
    orderParam: "order",
  },
  laboratory: { module: "labs", subview: undefined, orderParam: "order" },
  procedure: {
    module: "procedures",
    subview: undefined,
    orderParam: "procedureId",
  },
  plan: {
    module: "plans",
    subview: undefined,
    orderParam: "planId",
  },
  unknown: {
    module: "dashboard",
    subview: undefined,
    orderParam: undefined,
  },
}

export { INVOICE_ORDER_TYPE_MAP }
