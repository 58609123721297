import { MEDICATION_CATALOG } from "data"

const medsQueryKeys = {
  all: ["patient/medication-requests"] as const,
  catalogs: (dfo?: boolean, category?: string) => [
    "medicationCatalogs",
    ...(dfo !== undefined ? [dfo] : []),
    ...(category ? [category] : []),
  ],
  medicationKnowledge: (catalogId?: string, filter = "", medCodes = "", category?: MEDICATION_CATALOG) => [
    "medicationRequests/medication-knowledge",
    ...(catalogId ? [catalogId] : []),
    ...(filter ? [filter] : []),
    ...(medCodes ? [medCodes] : []),
    ...(category ? [category] : []),
  ],
  medicationRequestList: (
    patientId: string,
    category: "medication" | "nutraceutical",
    statusFilter?: string[],
    searchText?: string,
    perPage?: number,
    page?: number,
    encounter?: string,
    autoship?: boolean,
  ) => [
    "medication-requests",
    patientId,
    category,
    ...(statusFilter?.length ? [statusFilter] : []),
    ...(searchText ? [searchText] : []),
    ...(perPage ? [perPage] : []),
    ...(page ? [page] : []),
    ...(encounter ? [encounter] : []),
    ...(autoship !== undefined ? [autoship] : []),
  ],
  monograph: (monographId?: string) => ["medicationRequests/monograph", ...(monographId ? [monographId] : [])],
  orderDetails: {
    all: ["medicationRequests/medication-order-details"] as const,
    details: (orderId: string) => [...medsQueryKeys.orderDetails.all, orderId],
  },
  draft: {
    all: ["patient/medication-requests"] as const, // ask why this is the queryKey and not "medicationRequests/draft-medication-requests"
    list: (patientId: string, category: string) => [...medsQueryKeys.draft.all, patientId, category],
  },
  orders: {
    all: ["patient-medication-orders"] as const,
    withPatientId: (patientId: string, category: "medication-order" | "nutraceutical-order") =>
      [...medsQueryKeys.orders.all, patientId, category] as const,
    withStatusFilter: (
      patientId: string,
      category: "medication-order" | "nutraceutical-order",
      statusFilter: string[],
    ) => [...medsQueryKeys.orders.withPatientId(patientId, category), statusFilter] as const,

    withStatusAndSearchFilter: (
      patientId: string,
      category: "medication-order" | "nutraceutical-order",
      statusFilter: string[],
      searchFilter?: string,
    ) => [...medsQueryKeys.orders.withStatusFilter(patientId, category, statusFilter), searchFilter] as const,
  },
  auditEvents: (organizationId: string, patientId: string, orderId: string) => [
    "patient-medication-order-history",
    organizationId,
    patientId,
    orderId,
  ],
}

export { medsQueryKeys }
