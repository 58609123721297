import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { cpoeOrdersKeys } from "orders"
import { displayNotificationSuccess, getCommonCode } from "utils"
import { registerErrorTrace } from "logger"

import { draftMedicationRequestKeys } from "../query-keys"

const useCreateMedicationRequest = (onSuccess?: () => void, customNotification?: () => void) => {
  const { create, search } = useClient()
  const queryClient = useQueryClient()

  const newMedicationReq = async (medicationReq: MedicationRequest) => {
    const filters = new URLSearchParams({
      code: getCommonCode({ codes: medicationReq.medication?.CodeableConcept?.coding }),
      status: "draft",
      _count: "0",
    })
    const bundle = await search({ endpoint: `Patient/${medicationReq.subject.id}/MedicationRequest`, filters })
    if ((bundle.total ?? 0) > 0) {
      throw registerErrorTrace(new Error("This medication has been already prescribed!") as CustomError)
    }

    return await create("MedicationRequest", medicationReq)
  }

  const { mutate: createMedicationReq, isPending: isAdding } = useMutation({
    mutationFn: newMedicationReq,
    onError: async (error: CustomError) => {
      await queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      displayNotificationError(error)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      await queryClient.invalidateQueries({ queryKey: cpoeOrdersKeys.all })

      onSuccess?.()
    },
    onSettled: (_, error) => {
      if (!error)
        customNotification ? customNotification() : displayNotificationSuccess("Medication added successfully!")
    },
  })

  return { createMedicationReq, isAdding }
}

export { useCreateMedicationRequest }
