import { Patient } from "fhir"
import { getFirstEmail, getFirstPhone } from "fhir/utils"
import { FormikHelpers } from "formik"

import { EmailField, PhoneField } from "commons"
import { useUpdateUserEmail } from "commons/hooks"
import { ProfileSection } from "data"
import { useLoginContext } from "security"

import { usePatchPatient } from "../hooks"
import { InformationCard } from "./InformationCard"
import { telecomValidationSchema } from "./validations"

const ContactInformation = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { patchPatient, isPatching } = usePatchPatient()
  const { updateEmail, isPending } = useUpdateUserEmail(loggedInPatientId)

  const initialValues: Partial<Patient> = { telecom: loggedInPatient.telecom }
  const { telecom } = initialValues

  const onSubmit = (patientData: Partial<Patient>, _: FormikHelpers<Partial<Patient>>, activeItem?: string) => {
    if (activeItem === "Email" && telecom?.[0]?.value !== patientData.telecom?.[0].value) {
      updateEmail({
        patientId: loggedInPatientId,
        email: patientData.telecom?.[0].value as string,
      })
    } else {
      patchPatient({ patientId: loggedInPatientId, patientData: { ...patientData, meta: loggedInPatient.meta } })
    }
  }

  return (
    <InformationCard
      id={ProfileSection.CONTACT}
      title="Contact Information"
      data={{
        Email: getFirstEmail(telecom),
        Telephone: getFirstPhone(telecom),
      }}
      lineEditData={[]}
      initialValue={initialValues}
      validationSchema={telecomValidationSchema}
      isUpdating={isPatching || isPending}
      onSubmit={onSubmit}
      className="profile-card-section"
    >
      {({ currentActiveItem }: { currentActiveItem?: string }) => {
        return currentActiveItem === "Email" ? (
          <EmailField field="telecom[0].value" label="Email" className="md:col-span-2" validateDuplicate />
        ) : (
          <PhoneField field="telecom[1].value" label="Phone" className="md:col-span-2" />
        )
      }}
    </InformationCard>
  )
}

export { ContactInformation }
