import { faCalendarClock, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faStop } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, Money } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { ConfirmDialog } from "primereact/confirmdialog"
import { MenuItem } from "primereact/menuitem"
import { SelectButton } from "primereact/selectbutton"
import { startTransition, useId, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import {
  FooterActions,
  GroupedList,
  SearchWithStatus,
  SkeletonLoader,
  useChargeItemDefinitions,
  useSidebarContext,
} from "commons"
import {
  MedicationDataItem,
  MedicationKnowledgeDetails,
  useMedicationRequestGroupByDate,
  useMedicationRequests,
  useStopMedicationRequest,
  completeMR,
  stopMR,
  RescheduleDialog,
  useRescheduleMedicationRequest,
  useMedReducerState,
} from "commons/meds"
import { useLoginContext } from "security"

import { useCompleteMedicationRequest } from "../hooks"
import { Contents, MedicationRequestData } from "../types"

const MedicationNextRefills = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { loggedInPatientId } = useLoginContext()
  const { organizationId } = useSidebarContext()
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false)
  const [itemActionClicked, onActionCliked] = useState("")
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [selectedMKPrice, setSelectedMKPrice] = useState<Money>()

  const { statusFilter, searchFilter, perPage, page, updateSearchText } = useMedReducerState({
    statusFilter: ["active", "draft", "on-hold"],
  })

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    isLoading,
    medCodes,
    reloadMedications,
    fetchNextPage,
    hasNextPage,
  } = useMedicationRequests(loggedInPatientId as string, "nutraceutical", statusFilter, perPage, page, searchFilter)
  const { chargeItemDefinitions } = useChargeItemDefinitions(organizationId, {
    billToPatientCIDs: medCodes,
  })

  const { medicationRequestDataGroupByDate: mrDateGroups } = useMedicationRequestGroupByDate(
    medicationRequests,
    medicationKnowledges,
    chargeItemDefinitions.billToPatientCIDs,
    medicationDispenses,
  )

  const { rescheduleMedicationRequest, isLoading: isRescheduling } = useRescheduleMedicationRequest(() => {
    setShowRescheduleDialog(false)
    setSelectedDates([])
    reloadMedications()
  })

  const { stopMedicationRequest, isLoading: isStopping } = useStopMedicationRequest({
    onSettled: () => reloadMedications(),
    refreshMedications: () => onActionCliked(""),
  })
  const { completeMedicationRequest, isLoading: isCompleting } = useCompleteMedicationRequest(
    () => reloadMedications(),
    () => onActionCliked(""),
  )

  const handleSelect = (date: string, checked: boolean) => {
    if (checked) {
      setSelectedDates([...selectedDates, date])
    } else {
      setSelectedDates(selectedDates.filter((d) => d !== date))
    }
  }

  const selectedMrs = useMemo(() => {
    const groups = mrDateGroups.filter((mrg) => selectedDates.includes(mrg.key))
    return groups.reduce<string[]>((acc, g) => {
      const ids = g.items.map((item) => item.medicationRequestInfo.id as string) ?? []
      return [...acc, ...ids]
    }, [])
  }, [selectedDates, mrDateGroups])

  const handleReschedule = (rescheduleDate: string) => {
    rescheduleMedicationRequest({ medicationRequests: selectedMrs, rescheduleDate })
  }

  const stop = (medReqId: string) => {
    stopMR(medReqId, () => {
      onActionCliked(medReqId)
      stopMedicationRequest([medReqId])
    })
  }

  const complete = (medReqId: string) => {
    completeMR(medReqId, () => {
      onActionCliked(medReqId)
      completeMedicationRequest(medReqId)
    })
  }

  const getMRAllowedActions = (medicationData: MedicationRequestData) => {
    const status = medicationData.medicationRequestInfo.status

    const enableComplete = status === "active"
    const enableStop = status === "active"

    const mrActionsItems = [
      {
        label: "Complete",
        icon: <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />,
        command: () => {
          complete(medicationData.medicationRequestInfo.id as string)
        },
        disabled: !enableComplete,
      },
      {
        label: "Stop",
        icon: <FontAwesomeIcon icon={faStop} size="sm" className="mr-2" />,
        command: () => {
          stop(medicationData.medicationRequestInfo.id as string)
        },
        disabled: !enableStop,
      },
    ] as MenuItem[]

    return mrActionsItems
  }

  const loaderKey = useId()
  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="6rem"
      extraLine
    />
  )

  const addOptions = useMemo(
    () => [
      {
        label: "Reschedule",
        icon: faCalendarClock,
        command: () => setShowRescheduleDialog(true),
        disabled: !selectedDates.length,
      },
    ],
    [selectedDates],
  )

  return (
    <div className="w-full h-full bg-white flex flex-col p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-center md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => onUpdateContent(e.value as Contents)}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
        />

        <SearchWithStatus
          placeholder="Search products"
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        <div className="flex flex-1 flex-col overflow-auto">
          <SkeletonLoader
            repeats={3}
            containerClassName="p-5 overflow-auto"
            loaderType="list"
            skeletonShape="rectangle"
            skeletonSize="6rem"
            extraLine
          />
        </div>
      ) : mrDateGroups.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No products found</h3>
        </div>
      ) : (
        <div className="flex flex-1 flex-col overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <GroupedList
              className="grow"
              groups={mrDateGroups}
              renderItem={(medication) => (
                <MedicationDataItem
                  key={medication.medicationRequestInfo.id}
                  medicationKnowledge={medication.medicationKnowledge}
                  medicationRequest={medication.medicationRequestInfo}
                  medicationDispense={medication.medicationDispense}
                  pricePerUnit={medication.pricePerUnit}
                  showInstructions
                  showPackagingType
                  showStatus
                  showDispense
                  onClick={() => {
                    setSelectedMK(medication.medicationKnowledge)
                    setSelectedMKPrice(medication.pricePerUnit)
                  }}
                  dropdownMenuItems={getMRAllowedActions(medication)}
                  isDropdownLoading={
                    (isStopping || isCompleting) && itemActionClicked === medication.medicationRequestInfo.id
                  }
                />
              )}
              renderDivider={(key) => (
                <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 py-1 text-sm font-medium text-gray-500">
                  <div className="flex items-center gap-2 px-2 py-1">
                    <Checkbox
                      inputId={key}
                      checked={selectedDates.includes(key)}
                      onChange={(e) => handleSelect(key, !!e.checked)}
                    />
                    <label htmlFor={key} className="text-base">
                      {key}
                    </label>
                  </div>
                </div>
              )}
              renderEmptyState={() => (
                <div className="text-center m-auto mt-20">
                  <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No laboratory found</h3>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}

      <FooterActions actions={addOptions} actionsDisabled={isStopping || isRescheduling} />

      {showRescheduleDialog && (
        <RescheduleDialog
          onHide={() => {
            setShowRescheduleDialog(false)
          }}
          onReschedule={handleReschedule}
          title="Reschedule prescriptions"
          isLoading={isRescheduling}
        />
      )}
      <ConfirmDialog />
      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        pricePerUnit={selectedMKPrice}
      />
    </div>
  )
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

export { MedicationNextRefills }
