import { Duration, MedicationKnowledge } from "fhir"

import { dispenseInterval } from "commons/meds"
import { SYSTEM_VALUES } from "system-values"

const getMedicationKnowledgeSku = (mk: MedicationKnowledge) =>
  mk.code?.coding?.find(({ system }) => system === SYSTEM_VALUES.SKU)?.code

const getDispenseFrequency = (interval: Duration | undefined) => {
  const dispense =
    interval &&
    dispenseInterval.find(
      ({ value: { code, system, unit, value } }) =>
        interval?.system === system && interval?.code === code && interval?.value === value && interval?.unit === unit,
    )

  return dispense
}

export { getMedicationKnowledgeSku, getDispenseFrequency }
