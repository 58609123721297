import { ConfirmDialog } from "primereact/confirmdialog"
import { SelectButton } from "primereact/selectbutton"
import { startTransition } from "react"

import { SearchWithStatus } from "commons"
import { medicationRequestStatus } from "data"
import { useMedReducerState } from "commons/meds"

import { Contents } from "../types"
import { PrescriptionList } from "./PrescriptionList"

const MedicationRequestDataContainer = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useMedReducerState()

  return (
    <div className="w-full h-full bg-white flex flex-col p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-center md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
          onChange={(e) => onUpdateContent(e.value)}
        />
        <SearchWithStatus
          placeholder="Search medications"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      <PrescriptionList searchFilter={searchFilter} statusFilter={statusFilter} />
      <ConfirmDialog />
    </div>
  )
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

export { MedicationRequestDataContainer }
