import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MedicationRequest, getResources } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { cpoeOrdersKeys } from "orders"
import { displayNotificationSuccess, getCommonCode } from "utils"
import { registerErrorTrace } from "logger"

import { draftMedicationRequestKeys } from "../query-keys"

const useUpdateMedicationRequest = (onSuccess?: () => void, customNotification?: () => void) => {
  const { patch, search } = useClient()
  const queryClient = useQueryClient()

  const patchMedicationReq = async (medicationReq: MedicationRequest) => {
    const filters = new URLSearchParams({
      code: getCommonCode({ codes: medicationReq.medication?.CodeableConcept?.coding }),
      status: "draft",
    })
    const bundle = await search({ endpoint: `Patient/${medicationReq.subject.id}/MedicationRequest`, filters })
    if (!bundle.total) {
      queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      throw registerErrorTrace(new Error("This medication hasn't been prescribed!") as CustomError)
    }
    const mr = getResources<MedicationRequest>(bundle, "MedicationRequest")

    return patch("MedicationRequest", mr[0].id as string, medicationReq)
  }

  const { mutate: updateMedicationReq, isPending: isUpdating } = useMutation({
    mutationFn: patchMedicationReq,
    onError: async (error: CustomError) => {
      await queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      displayNotificationError(error)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      await queryClient.invalidateQueries({ queryKey: cpoeOrdersKeys.all })

      onSuccess?.()
      customNotification?.() ?? displayNotificationSuccess("Medication updated successfully!")
    },
  })

  return { updateMedicationReq, isUpdating }
}

export { useUpdateMedicationRequest }
