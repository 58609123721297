import { useState } from "react"
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"

import { SimpleBreadCrumb } from "commons"
import { useLoginContext } from "security"
import { getSubviewPath } from "utils"

import { SelectedCPDetails } from "./SelectedCPDetails"
import { AppointmentHistoryContainer } from "./AppointmentHistoryContainer"

const PlanDetails = () => {
  const { loggedInPatientId } = useLoginContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const planId = searchParams.get("planId") as string

  const [selectedPlanId, setSelectedPlanId] = useState(planId)

  const showOrder = (srId: string) => {
    navigate({
      pathname: getSubviewPath(loggedInPatientId, "labs"),
      search: `?${createSearchParams({ order: srId })}`,
    })
  }

  const showAssessment = (mcPlanId: string) => {
    navigate({
      pathname: getSubviewPath(loggedInPatientId, "mc"),
      search: `?${createSearchParams({ assessment: mcPlanId })}`,
    })
  }

  const goBack = () => {
    searchParams.delete("planId")
    setSearchParams(searchParams)
  }

  return (
    <div className="flex flex-col h-full overflow-hidden p-3 lg:px-5 pb-0">
      <SimpleBreadCrumb goHome={goBack} title="Plan Details" />

      <div className="@container flex flex-1 justify-between gap-4 overflow-hidden pb-6 pt-3">
        <SelectedCPDetails {...{ selectedPlanId, showOrder, showAssessment }} />
        <AppointmentHistoryContainer {...{ planId, selectedPlanId, setSelectedPlanId }} />
      </div>
    </div>
  )
}

export { PlanDetails }
