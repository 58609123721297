import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, MedicationDispense, MedicationKnowledge, MedicationRequest } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getCommonCode, getMedCodes } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationRequests = (
  patientId: string,
  category: "medication" | "nutraceutical",
  statusFilter: string[],
  perPage?: number,
  page?: number,
  searchText?: string,
  encounter?: string,
  autoship?: boolean,
) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.medicationRequestList(
    patientId,
    category,
    statusFilter,
    searchText,
    perPage,
    page,
    encounter,
  )

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    MedicationRequestsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = page, signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-prescriptions",
        _patient: patientId,
        category,
        ...(autoship ? { _autoship: `${autoship}` } : {}),
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        _count: perPage?.toString() ?? "20",
        _page: `${pageParam}`,
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: "MedicationRequest", filters, signal })

      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        medicationRequests,
        medicationDispenses,
        medicationKnowledges,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText, perPage, page } },
  })

  const { medicationRequests, draftMedicationRequestsCount, medicationKnowledges, medicationDispenses, medCodes } =
    useMemo(() => {
      const mrs = data?.pages.flatMap((page) => page.medicationRequests)
      const mks = data?.pages.flatMap((page) => page.medicationKnowledges)
      const mds = data?.pages.flatMap((page) => page.medicationDispenses)

      const medCodes = getMedCodes({ meds: mrs, withQty: true })

      const draftMRs = mrs?.filter((medicationRequest) => medicationRequest.status === "draft")?.length

      const medicationKnowledges = mks?.reduce(
        (acc, mk) => {
          const code = getCommonCode({ codes: mk.code?.coding })
          return { ...acc, [code]: mk }
        },
        {} as Record<string, MedicationKnowledge>,
      )
      return {
        medicationRequests: mrs,
        medicationKnowledges,
        medicationDispenses: mds,
        draftMedicationRequestsCount: draftMRs ?? 0,
        total: data?.pages?.[0]?.total ?? 0,
        medCodes,
      }
    }, [data?.pages])

  const reloadMedications = () => {
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    medCodes,
    isLoading,
    draftMedicationRequestsCount,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadMedications,
  }
}

type MedicationRequestsQueryData = {
  medicationRequests: MedicationRequest[]
  medicationDispenses: MedicationDispense[]
  medicationKnowledges: MedicationKnowledge[]
  next: number | undefined
  total: number
}

export { useMedicationRequests }
