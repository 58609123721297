import { MenuItem } from "primereact/menuitem"
import { Menu } from "primereact/menu"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef } from "react"
import { faExternalLink, faSpinner, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons"

import { useSidebarContext } from "../hooks"

const ListItem = ({
  rowImg,
  chip,
  header,
  subHeader,
  dropdownMenuItems,
  actionItem,
  actionsClassName,
  onContentClick,
  externalAction,
  itemPadding = false,
  rowHover = true,
  isLoading = false,
  className,
}: Props) => {
  const menu = useRef<Menu>(null)
  const { isSmallScreen } = useSidebarContext()

  // Preference order dropdown, action, external-link
  const RenderLinks = () => {
    if (isLoading) {
      return <FontAwesomeIcon icon={faSpinner} spin className="text-slate-400 mr-1" size="lg" />
    }

    if (dropdownMenuItems) {
      return (
        <span className="flex items-center justify-center text-lg">
          <span
            className="mr-2 flex items-center justify-center h-8 w-8 font-bold cursor-pointer text-slate-400 rounded-full hover:bg-slate-100 overflow-hidden "
            aria-controls="popup_menu"
            aria-haspopup
            onClick={(event) => {
              menu.current && menu.current.toggle(event)
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </span>
          <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
        </span>
      )
    }

    if (actionItem) {
      return (
        <span
          title={actionItem.label}
          className={classNames(
            "item-actions flex justify-center items-center h-8 w-8 cursor-pointer text-slate-400 font-semibold rounded-full hover:bg-slate-100 mr-1",
            actionsClassName,
          )}
          onClick={(e) => !actionItem.disabled && actionItem.command?.({ originalEvent: e, item: actionItem })}
        >
          {actionItem.icon ?? actionItem.label}
        </span>
      )
    }

    if (externalAction) {
      return (
        <span
          className="inline-flex items-center justify-center text-slate-400 mr-2 cursor-pointer text-sm"
          title={externalAction}
          onClick={onContentClick}
        >
          {isSmallScreen && <span className="text-xs mr-1 text-nowrap">{externalAction}</span>}
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      )
    }

    return <></>
  }

  return (
    <li className={classNames("list-none", className)}>
      <span className={classNames("block w-full", { "hover:bg-gray-50": rowHover })}>
        <div className="min-w-0 flex items-center justify-between md:flex-1 w-full gap-1">
          <div
            className={classNames("flex items-center py-4 w-full", {
              "cursor-pointer": onContentClick && !externalAction,
              "px-4": itemPadding,
            })}
            onClick={onContentClick}
          >
            <div className="flex">
              {rowImg && <div className="flex-shrink-0">{rowImg}</div>}
              <div className={classNames("md:truncate flex-wrap", { "px-4": rowHover })}>
                <div className="flex text-sm">{header}</div>
                {subHeader && (
                  <div className="mt-2 flex">
                    <div className="flex flex-col md:flex-row md:items-center text-xs text-gray-500 md:divide-x md:divide-gray-400 gap-1 md:gap-2">
                      {subHeader}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end md:flex-row-reverse md:items-center gap-1 md:mr-3">
            <RenderLinks />
            {chip}
          </div>
        </div>
      </span>
    </li>
  )
}

type Props = {
  header: string | JSX.Element
  rowImg?: JSX.Element
  chip?: JSX.Element
  subHeader?: string | JSX.Element
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  onContentClick?(): void
  actionsClassName?: string
  externalAction?: string
  itemPadding?: boolean
  rowHover?: boolean
  isLoading?: boolean
  className?: string
}

export { ListItem }
