import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink, faQrcode, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { faEye, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { codeableConceptAsString } from "fhir"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { formatDate } from "date-fns"

import { formatsByTypes, MEDICATION_CATALOG } from "data"
import { getBadgeColor, strCapitalize } from "utils"
import { SurveyContainer, useQRUrl } from "surveys"
import { SkeletonLoader, Badge, ModalDialog } from "commons"
import { useLoginContext } from "security"
import { getStatus } from "laboratory/utils"

import { useCarePlanDetails, useQrCodeGenerator } from "../../hooks"
import { CPLabResultsDetails } from "./CPLabResultsDetails"
import { CPAssessmentsDetails } from "./CPAssessmentsDetails"
import { CPMedicationsDetails } from "./CPMedicationsDetails"
import { CPConsentsDetails } from "./CPConsentsDetails"

const PLAN_NOTES_FEATURE_DISPLAY = false

const SelectedCPDetails = ({ selectedPlanId, showOrder, showAssessment }: Props) => {
  const { loggedInPatientId: patientId, managingOrganizationId } = useLoginContext()
  const { getQrImage } = useQrCodeGenerator()
  const [imageUrl, setImageUrl] = useState("")
  const [questPreview, setQuestPreview] = useState<string>()
  const [params, setParams] = useSearchParams()
  const [showQrModal, setShowQrModal] = useState(false)
  const {
    carePlan,
    serviceRequest,
    questionnaires,
    appointment,
    procedure,
    isLoading,
    isProcedurePlan,
    isMCPlan,
    isOnlySurvey,
    mcPlanIds,
    rx,
    consents,
    nutraceuticals,
    refetch: onSurveyHide,
    isRefetching: isUpdatingSurvey,
  } = useCarePlanDetails(patientId, selectedPlanId)
  const { getQRUrl, isLoading: isLoadingQRUrl } = useQRUrl((data) => {
    const uri = data?.link?.value?.uri
    if (uri) {
      const image = getQrImage(uri)
      if (!image) return
      setImageUrl(image)
      setShowQrModal(true)
    }
  })

  const previewQuest = (questId?: string) => {
    setQuestPreview(questId)
    questId ? params.set("qrId", questId) : params.delete("qrId")
    setParams(params)
  }

  const hideDialog = async () => {
    previewQuest(undefined)
    await onSurveyHide()
  }

  const hideDialogWithDelay = () => {
    setTimeout(hideDialog, 1000)
  }

  const labStatus = serviceRequest && getStatus(serviceRequest)

  return (
    <div className="flex flex-col py-3 pr-3 pl-1 overflow-hidden h-full flex-1">
      {isLoading ? (
        <SkeletonLoader loaderType="two-lines" repeats={4} containerClassName="pt-6" />
      ) : !carePlan ? (
        <span className="font-medium text-lg text-gray-700">Invalid treatment plan id</span>
      ) : carePlan.status === "draft" ? (
        <span className="font-medium text-lg text-gray-700">This treatment plan hasn't been activated</span>
      ) : (
        <>
          <h3 className="font-semibold text-lg mb-5" title="Plan">
            {carePlan.title ?? "Treatment Plan Details"}
          </h3>
          <div className="flex flex-col grow overflow-y-auto flex-1">
            <div className="flex items-center justify-between">
              <span className="text-gray-900 text-sm">
                {appointment?.start || carePlan?.created
                  ? formatDate(
                      new Date(appointment?.start ?? (carePlan?.created as string)),
                      formatsByTypes.FULL_DATETIME_WITH_TIMEZONE,
                    )
                  : "No date"}
              </span>
              <Badge
                {...getBadgeColor(
                  carePlan.status === "revoked"
                    ? "cancelled"
                    : isMCPlan && carePlan.status === "active"
                      ? "pending"
                      : carePlan.status,
                )}
                className="h-min"
              />
            </div>
            <div className="flex flex-col divide-y space-y-3">
              <div className="flex flex-col justify-between mt-4 pt-4">
                <span className="font-medium text-sm text-gray-500 w-40">Questionnaires</span>
                <div className="flex flex-col mx-4 space-y-2 divide-y">
                  {questionnaires?.map((q) => (
                    <div key={q.qResponse?.id} className="flex justify-between pt-2">
                      <div className="flex w-1/2 justify-between">
                        <span className="text-sm text-gray-900">{q.questionnaire.title}</span>
                        <Badge
                          text={strCapitalize(q.qResponse?.status?.replace("-", " ") ?? "unknow")}
                          colorStyle={q.qResponse?.status === "completed" ? "green" : "yellow"}
                          className="flex-shrink-0 h-min"
                        />
                      </div>
                      <div className="flex space-x-4 mr-1">
                        {q.qResponse?.id && !isMCPlan && (
                          <FontAwesomeIcon
                            icon={isLoadingQRUrl ? faSpinner : faQrcode}
                            className={classNames("cursor-pointer text-gray-400", {
                              "animate-spin": isLoadingQRUrl,
                            })}
                            title="Generate QR"
                            onClick={() => getQRUrl(q.qResponse?.id as string)}
                            size="sm"
                          />
                        )}
                        {q.qResponse?.id && (
                          <FontAwesomeIcon
                            icon={q.qResponse?.status === "completed" ? faEye : faPencil}
                            className="cursor-pointer text-gray-400"
                            title={`${q.qResponse?.status === "completed" ? "Show" : "Answer"} survey`}
                            onClick={() => (isUpdatingSurvey ? undefined : previewQuest(q.qResponse?.id as string))}
                            aria-disabled={isUpdatingSurvey}
                            size="sm"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {!!consents?.length && (
                <div className="flex flex-col mt-4 pt-4">
                  <span className="font-medium text-sm text-gray-500 w-40">Consents</span>
                  <CPConsentsDetails consents={consents} />
                </div>
              )}
              {serviceRequest && (
                <>
                  <div className="flex justify-between py-2">
                    <div className="flex w-1/2 justify-between">
                      <span className="font-medium text-sm text-gray-500">Labs</span>
                      <Badge
                        {...getBadgeColor(
                          labStatus?.code === "revoked" ? "cancelled" : labStatus?.display ?? "unspecified",
                        )}
                        className="h-min"
                      />
                    </div>
                    {serviceRequest?.id && getStatus(serviceRequest)?.code !== "draft" && (
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        className="cursor-pointer text-gray-500 mr-4"
                        title="Show order"
                        onClick={() => showOrder(serviceRequest.id as string)}
                      />
                    )}
                  </div>
                  {!isOnlySurvey && (
                    <CPLabResultsDetails
                      patientId={patientId}
                      currentOrganizationId={managingOrganizationId}
                      labOrderId={serviceRequest?.id}
                    />
                  )}
                </>
              )}

              {!!rx.length && (
                <div className="flex flex-col justify-between mt-4 pt-4">
                  <span className="font-medium text-sm text-gray-500 w-40">Pharmaceuticals</span>
                  <CPMedicationsDetails
                    medicationRequests={rx}
                    organizationId={managingOrganizationId}
                    category={MEDICATION_CATALOG.RX}
                    className="mx-4"
                  />
                </div>
              )}
              {!!nutraceuticals.length && (
                <div className="flex flex-col justify-between mt-4 pt-4">
                  <span className="font-medium text-sm text-gray-500 w-40">Nutraceuticals</span>
                  <CPMedicationsDetails
                    medicationRequests={nutraceuticals}
                    organizationId={managingOrganizationId}
                    category={MEDICATION_CATALOG.NUTRA}
                    className="mx-4"
                  />
                </div>
              )}

              {PLAN_NOTES_FEATURE_DISPLAY && (
                <div className="flex flex-col mt-6 pt-6">
                  <span className="font-medium text-gray-700">Plans</span>
                  <span className="text-sm text-gray-900 mt-3.5">{carePlan?.note?.[0]?.text ?? "No notes"}</span>
                </div>
              )}
              {isProcedurePlan && procedure && (
                <div className="flex flex-col mt-6 pt-6 p-2">
                  <span className="font-medium text-gray-700">Procedures</span>
                  {procedure && (
                    <div className="flex justify-between pt-2">
                      <div className="flex w-1/2 justify-between">
                        <span className="text-sm text-gray-900">{codeableConceptAsString(procedure.code)}</span>
                        <Badge className="flex-shrink-0" {...getBadgeColor(procedure.status)} />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isMCPlan && (
                <div className="flex flex-col mt-6 pt-6 pb-12">
                  <span className="font-medium text-gray-700">Assessments</span>
                  <CPAssessmentsDetails
                    patientId={patientId}
                    mcAssessments={mcPlanIds?.length ? mcPlanIds : undefined}
                    showAssessment={showAssessment}
                    isLoading={false}
                  />
                </div>
              )}
            </div>

            <ModalDialog onCancel={() => setShowQrModal(false)} showCloseIcon visible={showQrModal}>
              <img className="m-auto" src={imageUrl} alt="QR" />
            </ModalDialog>
            <Dialog
              visible={!!questPreview}
              onHide={hideDialog}
              draggable={false}
              resizable={false}
              className="w-2/3 h-4/5"
              header="Preview questionnaire"
            >
              <SurveyContainer showBanner={false} onFinish={hideDialogWithDelay} />
            </Dialog>
          </div>
        </>
      )}
    </div>
  )
}

type Props = {
  selectedPlanId?: string
  showOrder(srId: string): void
  showAssessment(planId: string): void
}

export { SelectedCPDetails }
