import {
  Invoice,
  Money,
  RequestGroupActionArrayActionArray,
  MedicationRequestDispenseRequest,
  ServiceRequest,
  MedicationRequest,
  MedicationAdministration,
  Procedure,
  CodeableConcept,
  MedicationKnowledge,
  Duration,
  Parameters,
} from "fhir"

export type CpoeCustomRequest = {
  resource: RequestGroupActionArrayActionArray
  display: string
  manufacturer: string
  quantity: number
  pricePerUnit: Money
  medicationKnowledge?: MedicationKnowledge
  medicationRequestInfo: MedicationRequest
}

export type CartMedData = {
  id: string
  quantity: number
  interval: Duration
  requestedByPract: boolean
}

export enum ACTION_GROUP_CODES {
  PHARMA = "rx",
  LAB = "lab-order",
  PROCEDURE = "procedure",
  NUTRA = "nutraceutical",
}

export type ActionGroupCode = "rx" | "procedure" | "lab-order" | "nutraceutical"

export type CpoeRequest = {
  resource: RequestGroupActionArrayActionArray
  display: string
  type: ActionGroupCode
  unitPrice: Money
  quantity: number
  medicationData?: CpoeMedicationRequest
  laboratoryData?: CpoeLaboratoryRequest
  procedureData?: CpoeProcedureRequest
}

export type CpoeMedicationRequest = {
  medicationRequest?: MedicationRequest
  manufacturer: string
  dispenseRequest?: MedicationRequestDispenseRequest
  isProcedure: boolean
  isMedication: boolean
  isDfo?: boolean
  doseForm?: string
  packaging?: string
}

export type CpoeLaboratoryRequest = {
  serviceRequest: ServiceRequest
  panels: CpoeLaboratoryPanel[]
  panelsCount: number
  billingType: string
  combos?: number
}

export type CpoeLaboratoryPanel = {
  profile: ServiceRequest
  price?: Money
}

export type CpoeProcedureRequest = {
  medicationRequests: MedicationRequest[]
  medicationAdministrations: MedicationAdministration[]
  procedure: Procedure
  medsData: Array<MedData>
}

export type MedData = { id?: string; code?: CodeableConcept; price?: Money }

export type InvoiceItem = {
  productId?: string
  productType?: string
  description: string
  qty?: number
  price: number
}

export type CustomInvoiceData = {
  invoice?: Invoice
  items: Record<string, InvoiceItem[]>
  fees: InvoiceItem[]
  feesSubTotal: number
  shippingMethods: Record<string, InvoiceItem[]>
  shippingMethodSubtotal: number
  taxes: number
  itemsSubtotal: number
  discount: number
  discounts?: Record<string, InvoiceItem[]>
  productFees?: Record<string, InvoiceItem[]>
  productFeesSubtotal: number
  summary?: Parameters
}

export type SummaryParameter = "subtotal" | "taxes" | "discounts-total" | "discounts" | "total"

export const CPOE_ACTION_GROUP_SYSTEM = "http://chartedhealth.com/fhir/cpoe/action-group"
export const CPOE_ACTION_SYSTEM = "http://chartedhealth.com/fhir/cpoe/action"

export const RG_BILLING_ACTION_CODES = {
  PHARMA: "billing-type-rx",
  NUTRA: "billing-type-nutraceutical",
}
