import { faBooksMedical } from "@fortawesome/pro-regular-svg-icons"
import { startTransition, useId, useReducer } from "react"
import { useSearchParams } from "react-router-dom"

import { EmptyMessage, SearchWithStatus, SkeletonLoader, StackedListContainer } from "commons"
import { useLoginContext } from "security"

import { useCarePlans } from "../hooks"
import { planStatusOptions } from "../utils"
import { planItemModel } from "./planItemModel"

const PlanListContainer = () => {
  const loaderKey = useId()
  const { loggedInPatientId: patientId } = useLoginContext()
  const [params, setParams] = useSearchParams()
  const { statusFilter, searchFilter, updateFilter, updateSearchFilter } = useReducerState()

  const { plans, isLoading, isFetching } = useCarePlans({
    patientId,
    searchText: searchFilter,
    statusFilter: statusFilter,
  })

  const showDetails = (planId: string) => {
    params.set("planId", planId)
    setParams(params)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="module-container flex flex-col h-full relative overflow-auto p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:h-12 w-full mb-3">
        <SearchWithStatus
          placeholder="Search treatment plans"
          options={planStatusOptions()}
          selectedItems={statusFilter}
          onStatusCheck={updateFilter}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchFilter(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!plans?.length ? (
            <EmptyMessage message="No treatment plans found" icon={faBooksMedical} subMessage={false} />
          ) : (
            <div className="bg-white h-full overflow-auto">
              <StackedListContainer
                data={plans}
                itemModelBuilder={(item) => {
                  return planItemModel({
                    plan: item,
                    isLoading: isFetching,
                    viewPlan: () => showDetails(item.carePlan.id as string),
                    contextLabel: "treatment plan",
                  })
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

const initialState = {
  statusFilter: ["active", "completed"],
  searchFilter: undefined,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "update-status-filter" | "update-search-filter" | "update-item-action-clicked"
    payload: string[] | string | undefined
  },
) => {
  switch (type) {
    case "update-status-filter":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    case "update-item-action-clicked":
      return {
        ...state,
        itemActionClicked: payload as string | undefined,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ statusFilter, searchFilter, itemActionClicked }, dispatch] = useReducer(reducer, initialState)

  const updateFilter = (statusArray: string[]) => {
    dispatch({ type: "update-status-filter", payload: statusArray })
  }

  const updateSearchFilter = (searchText: string) => {
    dispatch({ type: "update-search-filter", payload: searchText })
  }

  const updateScopedItem = (payload?: string) => {
    dispatch({ type: "update-item-action-clicked", payload })
  }

  return { statusFilter, searchFilter, itemActionClicked, updateFilter, updateSearchFilter, updateScopedItem }
}

type State = {
  statusFilter: Array<string>
  searchFilter: string | undefined
  itemActionClicked?: string
}

export { PlanListContainer }
