import { useSearchParams } from "react-router-dom"

import { MedicationRequestDataContainer } from "./MedicationRequestDataContainer"
import { MedicationOrdersList, MedicationOrderDetails } from "./orders"
import { Contents } from "../types"
import "./MedicationRequestContainer.css"
import { PrescriptionsRefillsContainer } from "./PrescriptionsRefillsContainer"
import { prescriptionViewOptions } from "../data"

const MedicationContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onUpdateContent = (activeContent: Contents) =>
    setSearchParams({ ...(activeContent === "prescriptions" ? {} : { view: activeContent }) })

  if (searchParams.get("order") !== null) {
    return <MedicationOrderDetails />
  }

  const mrViews: Record<Exclude<Contents, "prescriptions">, JSX.Element> = {
    refills: <PrescriptionsRefillsContainer activeContent="refills" onUpdateContent={onUpdateContent} />,
    history: (
      <MedicationOrdersList
        activeContent="history"
        contentOptions={prescriptionViewOptions}
        onUpdateContent={onUpdateContent}
      />
    ),
  }

  const viewParam = searchParams.get("view") as Exclude<Contents, "prescriptions">

  if (viewParam) {
    return mrViews[viewParam]
  }

  return (
    <MedicationRequestDataContainer
      activeContent="prescriptions"
      contentOptions={prescriptionViewOptions}
      onUpdateContent={onUpdateContent}
    />
  )
}

export { MedicationContainer }
