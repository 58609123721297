import { FormikHelpers, FormikValues } from "formik"
import { ReactNode, useEffect, useState } from "react"
import { ObjectSchema } from "yup"

import { InformationCardContainer, InfoRow, SlideFormContainer } from "commons"

const InformationCard = <T extends FormikValues>({
  id,
  title,
  data,
  isUpdating,
  lineEditData,
  children,
  onSubmit,
  initialValue,
  validationSchema,
  className,
}: Props<T>) => {
  const [showForm, setShowForm] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formTitle, setFormTitle] = useState(title)
  const [formValue, setFormValue] = useState(initialValue)
  const [currentActiveItem, setCurrentActiveItem] = useState<string | undefined>()

  const submit = (data: T, formikHelpers?: FormikHelpers<T>) => {
    onSubmit(data, formikHelpers, currentActiveItem)
    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting && !isUpdating) setShowForm(false)
  }, [isUpdating])

  return (
    <InformationCardContainer
      id={id}
      title={title}
      onEdit={() => setShowForm(true)}
      showEdit={!lineEditData}
      className={className}
    >
      {Object.entries(data).map(([itemTitle, data], index) => (
        <InfoRow
          key={index}
          title={itemTitle}
          content={data}
          loading={isUpdating}
          editable={!!lineEditData}
          onEdit={() => {
            setFormTitle(`${title} - ${itemTitle}`)
            setFormValue(lineEditData?.[index] ?? initialValue)
            setCurrentActiveItem(itemTitle)
            setShowForm(true)
          }}
        />
      ))}
      <SlideFormContainer
        showSlide={showForm}
        title={formTitle}
        onCancel={() => setShowForm(false)}
        onSubmit={submit}
        initialValue={!lineEditData ? initialValue : formValue}
        validationSchema={validationSchema}
      >
        {typeof children === "function" ? children({ currentActiveItem }) : children}
      </SlideFormContainer>
    </InformationCardContainer>
  )
}

type Props<T> = {
  id: string
  title: string
  data: Record<string, string | JSX.Element>
  isUpdating: boolean
  lineEditData?: T[]
  initialValue: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: ObjectSchema<any>
  onSubmit(data: T, formikHelpers?: FormikHelpers<T>, activeItem?: string): void
  children: ReactNode | ((props: { currentActiveItem?: string }) => ReactNode)
  className?: string
}

export { InformationCard }
