import { faCalendarDays, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faClockRotateLeft, faFileInvoiceDollar, faPills, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { MedicationKnowledge, MedicationRequest, codeableConceptAsString } from "fhir"
import { Chip } from "primereact/chip"
import { ConfirmDialog } from "primereact/confirmdialog"
import { TabPanel, TabView } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { useEffect, useMemo, useReducer, useState } from "react"
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"

import { SkeletonLoader, useChargeItemDefinitions } from "commons"
import {
  getStatusClass,
  MedicationCancelDialog,
  MedicationKnowledgeDetails,
  MedicationKnowledgeImage,
  MedicationOrderDetailHeader,
  MedicationOrderTaskListItem,
  MedicationRequestItem,
  RescheduleDialog,
  RescheduleHistory,
  useCancelMrOrder,
  useMedicationRequestDataBind,
  useMrOrderDetails,
  usePrescriptionMrOrder,
  useRescheduleMrOrder,
} from "commons/meds"
import { BILLING_TYPES_CODES, formatsByTypes } from "data"
import { InvoiceListItem } from "invoices"
import { getMoneyCurrencyAlt, getSubviewPath } from "utils"
import { useLoginContext } from "security"

const MedicationOrderDetails = () => {
  const { loggedInPatientId: patientId, managingOrganizationId } = useLoginContext()
  const {
    rescheduleDialogVisible,
    cancelDialogVisible,
    rescheduleDate,
    cancelReason,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
  } = useStateReducer()
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const [params] = useSearchParams()
  const navigate = useNavigate()

  const orderId = params.get("order")

  const [cancelFutureOrder, setCancelFutureOrder] = useState("skip")

  const handleOnHideCancel = () => {
    hideCancelDialog()
    setCancelFutureOrder("skip")
  }

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medCodes,
    medicationDispenses,
    tasks,
    invoice,
    invoices,
    isLoading,
    missingInfoMessages,
    billingTypeCode,
    provenances,
    dispenseTrackCodes,
  } = useMrOrderDetails(patientId, orderId as string)
  const { chargeItemDefinitions } = useChargeItemDefinitions(
    managingOrganizationId,
    billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT ? { billToPatientCIDs: medCodes } : undefined,
  )
  const { medicationRequestData } = useMedicationRequestDataBind(
    medicationRequests,
    medicationKnowledges,
    billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT ? chargeItemDefinitions?.billToPatientCIDs ?? {} : undefined,
    medicationDispenses,
  )

  const { cancelMrOrder, isCancelling } = useCancelMrOrder("medication-order", hideCancelDialog)
  const { rescheduleMrOrder, isRescheduling } = useRescheduleMrOrder()
  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()

  const showInvoice = (invoiceId: string) => {
    navigate({
      pathname: getSubviewPath(patientId, "invoices"),
      search: `?${createSearchParams({ invoiceId: invoiceId })}`,
    })
  }

  const activeActions = useMemo(() => {
    const rescheduleDateString =
      serviceRequest?.occurrence?.dateTime ?? serviceRequest?.authoredOn ?? new Date().toISOString()
    let rescheduleDate = new Date(rescheduleDateString)
    if (rescheduleDate < new Date()) rescheduleDate = new Date()

    const dropdownItems = [
      {
        label: "Prescription",
        icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
        command: () => getPrescription({ serviceRequestId: orderId as string }),
        loading: isGettingPrescription,
      },
      {
        label: "Reschedule",
        icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
        disabled: invoice?.status === "balanced",
        command: () => {
          updateRescheduleDate(rescheduleDate)
          showRescheduleDialog()
        },
        loading: isRescheduling,
      },
      ...(invoice
        ? [
            {
              label: "See Invoice",
              icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
              command: () => showInvoice(invoice.id ?? ""),
            },
          ]
        : []),
      {
        label: "Cancel",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
        command: showCancelDialog,
      },
    ]

    switch (serviceRequest?.status) {
      case "active":
        return dropdownItems
      case "completed":
        return dropdownItems.filter((item) => item.label === "Prescription" || item.label === "Edit")
      default:
        return dropdownItems.filter((item) => item.label === "Prescription")
    }
  }, [
    orderId,
    serviceRequest,
    getPrescription,
    showCancelDialog,
    showRescheduleDialog,
    updateRescheduleDate,
    showInvoice,
    invoice,
    isRescheduling,
    isGettingPrescription,
  ])

  useEffect(() => {
    // Close all dialogs when order status changes from 'active'
    if (serviceRequest?.status !== "active") {
      hideCancelDialog()
      hideRescheduleDialog()
    }
  }, [serviceRequest?.status])

  return (
    <div className="flex flex-col h-full p-3 overflow-hidden">
      {isLoading ? (
        <>
          <SkeletonLoader loaderType="two-lines" repeats={3} extraLine />
        </>
      ) : (
        <>
          {!!missingInfoMessages?.length && (
            <div className="flex space-x-10 items-center justify-center">
              <div className="flex flex-col">
                {missingInfoMessages.map((msg, index) => (
                  <span key={index}>{msg}</span>
                ))}
              </div>
              <div className="flex flex-row justify-end">
                <button className="underline ml-4" onClick={() => showInvoice(invoice?.id ?? "")}>
                  See invoice
                </button>
              </div>
            </div>
          )}
          <div className="@container flex flex-col flex-1 gap-4 pb-6 overflow-y-scroll text-gray-600">
            <MedicationOrderDetailHeader
              className="flex flex-col gap-1 border-b pb-3"
              serviceRequest={serviceRequest}
              invoices={invoices}
              activeActions={activeActions}
              menuIsLoading={isCancelling}
            />
            <TabView className="flex flex-col h-full" panelContainerClassName="grow">
              <TabPanel
                header={<span className="text-sm">Details</span>}
                contentClassName="flex flex-col gap-4 overflow-y-scroll p-1 pt-4"
              >
                <div className="flex flex-col p-2 border rounded-lg">
                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      <label className="font-semibold m-2">Medications:</label>
                    </div>
                  </div>
                  {!medicationRequestData?.length ? (
                    <div className="flex flex-col items-center justify-center w-full h-full">
                      <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                      <p className="text-md text-slate-400 p-4 place-self-center">No medications requested</p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 @7xl:grid-cols-2 gap-2 2xl:gap-3 pb-4 text-sm content-start">
                      {medicationRequestData?.map((medicationData) => {
                        const dosageInstructions = medicationData.medicationRequestInfo.dosageInstruction
                        const lastMedicationDispense = medicationData.medicationDispense?.at(-1)
                        const pharmacyStatus =
                          lastMedicationDispense?.statusReason?.CodeableConcept &&
                          codeableConceptAsString(lastMedicationDispense?.statusReason?.CodeableConcept)

                        const medProvenance = provenances?.[medicationData.medicationRequestInfo.id as string]
                        const trackingCode = dispenseTrackCodes.filter(
                          (td) => td.mrId === (medicationData.medicationRequestInfo.id as string),
                        )
                        return (
                          <div
                            key={medicationData.medicationRequestInfo.id}
                            className={classNames(
                              "grid grid-flow-col grid-cols-4 gap-2 lg:gap-4 pt-4 border border-slate-300 rounded-xl p-3 xl:p-5",
                              medicationRequestData.indexOf(medicationData) !== 0 && "@sm:border-t @7xl:border-none",
                            )}
                          >
                            <div className="flex flex-wrap col-span-3">
                              <span
                                className={classNames(
                                  "w-24 min-w-[6rem] h-42 flex items-center justify-center",
                                  medicationData.medicationKnowledge && "cursor-pointer",
                                )}
                                onClick={() =>
                                  setSelectedMK({
                                    mk: medicationData.medicationKnowledge,
                                    mr: medicationData.medicationRequestInfo,
                                  })
                                }
                              >
                                <MedicationKnowledgeImage
                                  drugCharacteristic={medicationData.medicationKnowledge?.drugCharacteristic}
                                  className="w-full h-full object-cover"
                                />
                              </span>
                              <MedicationRequestItem
                                medicationRequest={medicationData.medicationRequestInfo}
                                medicationKnowledge={medicationData.medicationKnowledge ?? {}}
                                showPackagingType
                                amount={
                                  medicationData.medicationRequestInfo.dispenseRequest?.quantity?.value ??
                                  medicationData.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
                                  1
                                }
                                className="ml-3"
                                onClick={() =>
                                  setSelectedMK({
                                    mk: medicationData.medicationKnowledge,
                                    mr: medicationData.medicationRequestInfo,
                                  })
                                }
                              />
                            </div>
                            <div className="flex flex-col gap-2 row-start-1 col-span-1 justify-start items-end">
                              {!!medicationData.patientPrice?.value && (
                                <span title="Price" className="font-semibold text-lg">
                                  {getMoneyCurrencyAlt(medicationData.patientPrice.currency)}
                                  {medicationData.patientPrice.value.toFixed(2)}
                                </span>
                              )}
                              {pharmacyStatus && (
                                <div
                                  title="Pharmacy Status"
                                  className="font-bold text-sm text-ellipsis text-gray-500 -mt-1"
                                >
                                  {pharmacyStatus}
                                </div>
                              )}
                              <span title="Status">
                                <Chip
                                  label={medicationData.medicationRequestInfo.status}
                                  className={`custom-chip default capitalize text-sm ${getStatusClass(
                                    medicationData.medicationRequestInfo.status,
                                  )}`}
                                />
                              </span>
                            </div>
                            <div className="row-start-2 col-start-1 col-span-4 ml-3">
                              {dosageInstructions?.length && (
                                <div title="Instructions" className="gap-x-4 ">
                                  {dosageInstructions?.map((instruction, index) => (
                                    <p key={instruction.id ?? index}>{instruction.text}</p>
                                  ))}
                                </div>
                              )}
                            </div>
                            {medProvenance?.length ? (
                              <div className="row-start-3 col-start-1 col-span-4 flex flex-col gap-1 ml-3">
                                <span className="text-gray-900 font-medium">Dispense History:</span>
                                <div className="flex flex-col">
                                  {medProvenance.map(({ provenance, trackingCode }, index) => {
                                    const activityName = codeableConceptAsString(provenance.activity)
                                    return (
                                      <div
                                        className="flex mt-2 items-center"
                                        title="Activity"
                                        key={provenance.id ?? index}
                                      >
                                        <span>{activityName}</span>
                                        {!!provenance.occurred?.dateTime && (
                                          <div className="flex items-center ml-2 pl-2 border-l" title="Ocurred">
                                            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mx-1" />
                                            {formatDate(
                                              parseISO(provenance.occurred.dateTime),
                                              formatsByTypes.LONG_DATE,
                                            )}
                                          </div>
                                        )}
                                        {activityName === "Shipped" && !!trackingCode && (
                                          <span
                                            key={index}
                                            className="border-l font-semibold ml-2 pl-2"
                                            title="Tracking Number"
                                          >
                                            #{trackingCode}
                                          </span>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            ) : trackingCode.length ? (
                              <div className="row-start-3 col-start-1 col-span-4 flex flex-col gap-1 ml-3">
                                <span className="text-gray-900 font-medium">Dispense History:</span>
                                <div className="flex flex-col">
                                  {trackingCode.map(({ identifier, status, dateShipped }, index) => {
                                    return (
                                      <div className="flex mt-2 items-center" title="Activity" key={index}>
                                        <span>{status}</span>
                                        {!!dateShipped && (
                                          <div className="flex items-center ml-2 pl-2 border-l" title="Ocurred">
                                            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mx-1" />
                                            {formatDate(parseISO(dateShipped), formatsByTypes.LONG_DATE)}
                                          </div>
                                        )}
                                        {!!identifier && (
                                          <span
                                            key={index}
                                            className="border-l font-semibold ml-2 pl-2"
                                            title="Tracking Number"
                                          >
                                            #{identifier}
                                          </span>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>

                {!!tasks?.length && (
                  <div className="flex flex-col pr-1 border rounded-lg p-4">
                    <label className="font-semibold ml-2 ">Tasks:</label>
                    <ul>{tasks?.map((task) => <MedicationOrderTaskListItem key={task.id} task={task} />)}</ul>
                  </div>
                )}

                {!!invoices?.length && (
                  <div className="flex flex-col pr-1 border p-4 rounded-lg">
                    <label className="font-semibold ml-2 ">Invoice: </label>
                    <ul>
                      {invoices.map((inv, index) => (
                        <InvoiceListItem
                          key={inv.id ?? index}
                          invoice={inv}
                          onClick={() => showInvoice(inv.id ?? "")}
                          className="last:mb-0"
                        />
                      ))}
                    </ul>
                  </div>
                )}
              </TabPanel>
              <TabPanel contentClassName="h-full flex flex-1 p-4" header={<span className="text-sm">Reschedules</span>}>
                <RescheduleHistory orderId={orderId as string} />
              </TabPanel>
            </TabView>
          </div>
        </>
      )}

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />

      <ConfirmDialog />

      {!!rescheduleDialogVisible && (
        <RescheduleDialog
          title="Reschedule order"
          initialDate={rescheduleDate}
          onReschedule={(date) => {
            rescheduleMrOrder({ id: orderId as string, newDate: new Date(parseISO(date)) })
            hideRescheduleDialog()
          }}
          onHide={hideRescheduleDialog}
          isLoading={isRescheduling}
        />
      )}

      <MedicationCancelDialog
        invoice={invoice}
        visible={cancelDialogVisible}
        cancelFutureOrder={cancelFutureOrder}
        isCancelling={isCancelling}
        onHide={handleOnHideCancel}
        cancelReason={cancelReason}
        onConfirm={() =>
          cancelMrOrder({
            serviceRequestId: orderId as string,
            patientId: patientId as string,
            cancelReason: cancelReason as string,
            stopSequence: true,
          })
        }
        updateCancelFutureOrder={setCancelFutureOrder}
        updateCancelReason={updateCancelReason}
      />
    </div>
  )
}

const INITIAL_STATE: State = {
  rescheduleDialogVisible: false,
  rescheduleDate: new Date(),
  cancelDialogVisible: false,
}

const reducer = (
  state: State,
  { type, payload }: { type: string; payload?: boolean | Date | string | MedicationKnowledge },
) => {
  switch (type) {
    case "updateRescheduleDialogVisible":
      return { ...state, rescheduleDialogVisible: payload as boolean }
    case "updateCancelDialogVisible":
      return {
        ...state,
        cancelDialogVisible: payload as boolean,
        cancelReason: payload !== false ? state.cancelReason : undefined,
      }
    case "updateRescheduleDate":
      return { ...state, rescheduleDate: payload as Date }
    case "updateCancelReason":
      return { ...state, cancelReason: payload as string }
    case "updateSelectedMK":
      return { ...state, selectedMK: payload as MedicationKnowledge | undefined }
    default:
      return state
  }
}

const useStateReducer = () => {
  const [{ rescheduleDialogVisible, cancelDialogVisible, cancelReason, selectedMK, rescheduleDate }, dispatch] =
    useReducer(reducer, INITIAL_STATE)

  const showRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: true })
  const hideRescheduleDialog = () => dispatch({ type: "updateRescheduleDialogVisible", payload: false })
  const showCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: true })
  const hideCancelDialog = () => dispatch({ type: "updateCancelDialogVisible", payload: false })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "updateRescheduleDate", payload: newDate })
  const updateCancelReason = (reason: string) => dispatch({ type: "updateCancelReason", payload: reason })
  const updateSelectedMK = (mk?: MedicationKnowledge) => dispatch({ type: "updateSelectedMK", payload: mk })

  return {
    rescheduleDialogVisible,
    cancelDialogVisible,
    cancelReason,
    selectedMK,
    rescheduleDate,
    showRescheduleDialog,
    hideRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    updateRescheduleDate,
    updateCancelReason,
    updateSelectedMK,
  }
}

type State = {
  selectedMK?: MedicationKnowledge
  rescheduleDialogVisible: boolean
  rescheduleDate: Date
  cancelDialogVisible: boolean
  cancelReason?: string
}

export { MedicationOrderDetails }
