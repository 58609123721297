import { ReactNode, useId, useState } from "react"
import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPills } from "@fortawesome/pro-regular-svg-icons"
import { faClockRotateLeft, faPlus, faStop } from "@fortawesome/pro-solid-svg-icons"
import { MedicationRequest } from "fhir"
import InfiniteScroll from "react-infinite-scroller"
import { classNames } from "primereact/utils"

import { Button, SkeletonLoader, useSidebarContext } from "commons"
import { useCPOEContext } from "orders"

import { MedicationKnowledgeDetails } from "./MedicationKnowledgeDetails"
import { MedicationRequestData, MRActions } from "../types"
import { MedicationDataItem } from "./MedicationDataItem"

const MedicationRequestList = ({
  data,
  totalMR,
  className,
  itemWaitingForAction,
  customEmptyMessage,
  hasNextPage,
  fetchNextPage,
  medicationRequestActions,
}: Props) => {
  const [selectedMK, setSelectedMK] = useState<MedicationRequestData>()

  const { purchaseEnabled } = useSidebarContext()
  const { gotoShop } = useCPOEContext()

  const loader = (
    <SkeletonLoader
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="6rem"
      extraLine
      repeats={1}
      key={useId()}
    />
  )

  const dropdownMenuItem = (mr: MedicationRequest): MenuItem[] => [
    ...(medicationRequestActions.renew
      ? [
          {
            label: "Reorder",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions.renew?.(mr)
            },
            disabled: !["completed", "stopped"].includes(mr.status),
          },
        ]
      : []),
    {
      label: "Stop",
      icon: <FontAwesomeIcon icon={faStop} size="sm" className="mr-2" />,
      command: () => {
        medicationRequestActions.stop?.(mr.id as string)
      },
      disabled: !["active", "on-hold"].includes(mr.status),
    },
  ]

  return (
    <div className={classNames("flex flex-col flex-1 overflow-hidden", className)}>
      {!totalMR || !data?.length ? (
        customEmptyMessage ?? (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon icon={faPills} size="2x" className="text-slate-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No Nutraceuticals Found</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by shop new products</p>
            {!!purchaseEnabled && (
              <Button
                icon={faPlus}
                label="Shop nutraceuticals"
                className="mt-6 create-prescription-button"
                onClick={gotoShop}
              />
            )}
          </div>
        )
      ) : (
        <div className="flex flex-1 flex-col overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader}>
            {data?.map((medication) => (
              <MedicationDataItem
                key={medication.medicationRequestInfo.id}
                medicationKnowledge={medication.medicationKnowledge}
                medicationRequest={medication.medicationRequestInfo}
                medicationDispense={medication.medicationDispense}
                pricePerUnit={medication.pricePerUnit}
                showInstructions
                showPackagingType
                showStatus
                showDispense
                onClick={() => {
                  setSelectedMK(medication)
                }}
                dropdownMenuItems={dropdownMenuItem(medication.medicationRequestInfo)}
                isDropdownLoading={itemWaitingForAction === medication.medicationRequestInfo.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.medicationKnowledge}
        mr={selectedMK?.medicationRequestInfo}
        onHide={() => setSelectedMK(undefined)}
      />
    </div>
  )
}

type Props = {
  data?: MedicationRequestData[]
  totalMR: number
  medicationRequestActions: MRActions
  className?: string
  customEmptyMessage?: JSX.Element | ReactNode
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  itemWaitingForAction?: string
  hasNextPage?: boolean
  fetchNextPage(): void
}

export { MedicationRequestList }
