enum PrescriptionViews {
  prescriptions = "prescriptions",
  history = "history",
  refills = "refills",
}

const prescriptionViewOptions = [
  { name: "Prescriptions", value: PrescriptionViews.prescriptions },
  { name: "Scheduled", value: PrescriptionViews.refills },
  { name: "History", value: PrescriptionViews.history },
]

export { prescriptionViewOptions, PrescriptionViews }
