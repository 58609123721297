import { classNames } from "primereact/utils"
import { useNavigate, useSearchParams } from "react-router-dom"

import { CreditCardContainer } from "account"
import { SmartyAddressVerificationProvider } from "commons/context"
import { CoveragesContainer } from "coverage"
import { ProfileSection } from "data"

import { AddressInformation } from "./AddressInformation"
import { BasicInformation } from "./BasicInformation"
import { ContactInformation } from "./ContactInformation"
import { ContactsInformation } from "./ContactsInformation"
import { PatientDocuments } from "./PatientDocuments"
import { ProfileNav } from "./PatientNav"

const PatientInformation = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const section = document.getElementById(searchParams.get("section") ?? "")

  if (!section) {
    navigate(`?section=${ProfileSection.BASIC}`)
  }

  section?.scrollIntoView({ block: "start", behavior: "smooth", inline: "start" })

  return (
    <div className={classNames("overflow-y-auto overflow-x-hidden flex max-md:h-full")}>
      <ProfileNav />
      <div className="p-3 overflow-y-auto overflow-x-hidden grid gap-4 m-auto pb-6 px-2 w-full lg:w-3/4 lg:h-full">
        <BasicInformation />
        <ContactInformation />
        <SmartyAddressVerificationProvider>
          <AddressInformation />
          <CreditCardContainer />
          <CoveragesContainer />
          <ContactsInformation />
        </SmartyAddressVerificationProvider>
        <PatientDocuments />
      </div>
    </div>
  )
}

export { PatientInformation }
