import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import {
  faCalendar,
  faCalendarCheck,
  faFileInvoiceDollar,
  faFlaskVial,
  faPrint,
  faUser,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { MenuItem } from "primereact/menuitem"
import { startTransition, useId, useReducer } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSearchParams } from "react-router-dom"

import {
  GroupedList,
  SearchWithStatus,
  SkeletonLoader,
  StackedListItem,
  StackedListItemProps,
  useShowSignedUrlDocument,
} from "commons"
import { BillingTypeCodes, LAB_REFERENCE_ID_CODE, formatsByTypes, laboratoryOrderStatusCodes } from "data"
import { useLoginContext } from "security"
import { formatDate, getBadgeColor, getMoneyCurrencyAlt, openLinkInNewTab, strCapitalize } from "utils"

import { useLaboratoryOrders } from "../hooks"
import { LaboratoryOrder } from "../types"
import { getStatus } from "../utils"

const LaboratoryOrderList = () => {
  const loaderKey = useId()
  const [params, setParams] = useSearchParams()
  const { loggedInPatientId, loggedInPatient } = useLoginContext()
  const currentOrganizationId = loggedInPatient.managingOrganization?.id ?? ""

  const { statusFilter, searchFilter, itemActionClicked, updateScopedItem, updateFilter, updateSearchFilter } =
    useReducerState()

  const { laboratoryOrders, hasNextPage, isLoading, fetchNextPage } = useLaboratoryOrders(
    currentOrganizationId,
    loggedInPatientId,
    statusFilter ?? [],
    searchFilter,
  )

  const { showDocument } = useShowSignedUrlDocument(openLinkInNewTab)

  const goToOrderDetails = (orderId: string) => {
    params.append("order", orderId)
    setParams(params)
  }

  const showLabDocument = async (orderId: string, docUrl: string) => {
    updateScopedItem(orderId)
    await showDocument(docUrl).finally(() => updateScopedItem(undefined))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="module-container flex flex-col h-full relative overflow-auto p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:h-12 w-full mb-3">
        <SearchWithStatus
          placeholder="Search orders"
          options={laboratoryOrderStatusCodes}
          selectedItems={statusFilter}
          onStatusCheck={updateFilter}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchFilter(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/5 lg:w-1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!laboratoryOrders?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No orders found</p>
            </div>
          ) : (
            <div className="bg-white h-full overflow-auto">
              <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                loader={loader()}
              >
                <GroupedList
                  className="grow"
                  groups={laboratoryOrders}
                  renderItem={(labData) => (
                    <StackedListItem
                      modelData={labOrderItemModel(
                        labData,
                        goToOrderDetails,
                        showLabDocument,
                        (orderId) => orderId === itemActionClicked,
                      )}
                      keepMenuSpace
                    />
                  )}
                  renderDivider={(key) => (
                    <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 py-1 text-sm font-medium text-gray-500">
                      <h3>{key}</h3>
                    </div>
                  )}
                />
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const labOrderItemModel = (
  data: LaboratoryOrder,
  goToOrderDetails: (value: string) => void,
  showDoc: (orderId: string, url: string) => void,
  isLoadingDocs?: (orderId: string) => boolean,
): StackedListItemProps => {
  const labOrderStatus = getStatus(data.order)
  const orderBillingTypeDisplay = data?.billingType?.replace("bill-", "")

  const labReferenceId =
    data.order?.identifier?.find(({ type }) => type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE))?.value ??
    "unavailable ID"

  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      ...(!data.order.id || !data.presentedForm || !data.latestResult?.released
        ? []
        : [
            {
              icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
              label: "View results",
              disabled: !data.order.id || !data.presentedForm,
              command: () => data.order.id && data.presentedForm && showDoc(data.order.id, data.presentedForm),
            },
          ]),
      ...(!data.order.id || !data.requisition
        ? []
        : [
            {
              icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
              label: "View requisition",
              disabled: !data.order.id || !data.requisition,
              command: () => data.order.id && data.requisition && showDoc(data.order.id, data.requisition),
            },
          ]),
    ]

    return menuItems
  }

  return {
    leftData: [
      {
        lineItems: [
          {
            name: "Laboratory",
            value: `${strCapitalize(data.order.performer?.[0]?.display as string)} (${labReferenceId})`,
          },
        ],
      },
      {
        lineItems: [
          {
            name: "Authored on",
            value: formatDate(parseISO(data.order.authoredOn as string), formatsByTypes.LONG_DATE),
            icon: faCalendar,
          },
          ...(data.combo ? [{ name: "Combos", value: "1", icon: faFlaskVial }] : []),
          ...(data.panelsCount ? [{ name: "Panels", value: data.panelsCount.toString(), icon: faVials }] : []),
          ...(data.order.occurrence?.dateTime
            ? [
                {
                  name: "Occurrence",
                  icon: faCalendarCheck,
                  value: formatDate(parseISO(data.order.occurrence?.dateTime as string), formatsByTypes.LONG_DATE),
                },
              ]
            : []),
          {
            name: "Bill to",
            value: strCapitalize(orderBillingTypeDisplay),
            icon: faFileInvoiceDollar,
          },
          { name: "Requester", value: data.requester, icon: faUser },
        ],
      },
    ],
    rightData: [
      {
        lineItems: ![BillingTypeCodes.BILL_PRACTICE as string, BillingTypeCodes.INSURANCE as string].includes(
          data.billingType,
        )
          ? [
              {
                name: "Price",
                value: `${getMoneyCurrencyAlt(data.price.currency)}${data.price.value?.toFixed(2)} ${
                  data.price.currency
                }`,
              },
            ]
          : [],
      },
    ],
    badge: getBadgeColor(labOrderStatus?.code === "revoked" ? "cancelled" : labOrderStatus?.display ?? "unspecified"),
    menu: getMenuItems(),
    isLoading: isLoadingDocs?.(data.order.id as string),
    onClick: () => goToOrderDetails(data.order.id as string),
  }
}

const initialState = {
  statusFilter: [
    "requisition-pending",
    "requisition-available",
    "preliminary-results",
    "final-results-available",
    "on-hold",
  ],
  searchFilter: undefined,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "update-status-filter" | "update-search-filter" | "update-item-action-clicked"
    payload: string[] | string | undefined
  },
) => {
  switch (type) {
    case "update-status-filter":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    case "update-item-action-clicked":
      return {
        ...state,
        itemActionClicked: payload as string | undefined,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ statusFilter, searchFilter, itemActionClicked }, dispatch] = useReducer(reducer, initialState)

  const updateFilter = (statusArray: string[]) => {
    dispatch({ type: "update-status-filter", payload: statusArray })
  }

  const updateSearchFilter = (searchText: string) => {
    dispatch({ type: "update-search-filter", payload: searchText })
  }

  const updateScopedItem = (payload?: string) => {
    dispatch({ type: "update-item-action-clicked", payload })
  }

  return { statusFilter, searchFilter, itemActionClicked, updateFilter, updateSearchFilter, updateScopedItem }
}

type State = {
  statusFilter: Array<string>
  searchFilter: string | undefined
  itemActionClicked?: string
}

export { LaboratoryOrderList }
